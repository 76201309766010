import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"

import Hero from "../components/Hero"
import Title from "../components/Title"
import HeroImg from "../images/hero-privacy-policy.jpg"
import LinedText from "../components/LinedText"
import BlurredSection from "../components/BlurredSection"
import Section from "../components/Section"
import { Tile, Wrapper, Grid } from "../components/UI/Grid"
import { List } from "../components/UI/List"
import Element from "../components/UI/Element"

const PrivacyPolicy = () => {
  return (
    <>
      <Seo title="Privacy Policy" />
      <BlurredSection>
        <Hero img={HeroImg} page="inner">
          <Wrapper>
            <TitleStyled size="d4" lg="d1">
              <LinedText title="Privacy Policy" />
            </TitleStyled>
          </Wrapper>
        </Hero>
        <Section>
          <Wrapper>
            <Grid justifyContent="center">
              <Tile lg={10}>
                <Element as="p" mb={9}>
                  The{" "}
                  <Element fontWeight="bold" color="brand" as="span">
                    TAJARA Leisure and Hospitality Group Inc.
                  </Element>{" "}
                  and its subsidiaries (individually and collectively referred
                  to as "TAJARA Hospitality") are committed to safeguarding the
                  privacy of their data subjects; this policy specifies how your
                  personal data is dealt with. Using the digital platforms that
                  are part and parcel of{" "}
                  <Element fontWeight="bold" as="span">
                    TAJARA Hospitality
                  </Element>
                  , implies that you accept the terms of this privacy policy.
                  <br />
                  <br />
                  <Element fontWeight="bold" as="span">
                    TAJARA Hospitality
                  </Element>
                  , as your data controller, shall ensure that the obligations
                  set forth by the Data Privacy Act of 2012 and its implementing
                  rules and regulations are carried out through the processing
                  of your personal information.
                  <br />
                  <br />
                  This privacy policy applies only to information collected
                  online through the official websites, mobile applications, and
                  social media pages (collectively referred to as "digital
                  platforms") and not to information collected offline. We may
                  amend this privacy policy at any time by publishing a new
                  version on this website.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  WHAT INFORMATION WE COLLECT
                </Element>
                <Element mb={9}>
                  <Element fontWeight="bold" as="span">
                    TAJARA Hospitality
                  </Element>{" "}
                  reserves the right to collect, store, and use the following
                  kinds of information:
                  <List listStyle="disc">
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Contact Number</li>
                    <li>Geographical location</li>
                    <li>IP address</li>
                    <li>Browser type and version</li>
                    <li>Operating system</li>
                    <li>Referral source</li>
                    <li>Length of visit</li>
                    <li>Page views</li>
                    <li>Website navigation; and</li>
                    <li>
                      Any other personal information that you opt to provide to
                      us
                    </li>
                  </List>
                  <Element fontWeight="bold" as="span">
                    TAJARA Hospitality
                  </Element>
                  reserves the right to collect information from its users when
                  users browse the company's websites and submit questions
                  through the Contact Us page.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  WHAT WE USE YOUR INFORMATION FOR
                </Element>
                <Element mb={9}>
                  Any of the relevant information collected from you may be used
                  in one of the following ways:
                  <List listStyle="disc">
                    <li>
                      To personalize your experience. Your information helps us
                      to better respond to your individual needs to improve our
                      digital platforms. We continually strive to improve our
                      offerings based on the information and feedback we receive
                      from you.
                    </li>
                    <li>
                      To improve customer service. Your information helps us to
                      more effectively respond to your customer service requests
                      and support your needs.
                    </li>
                    <li>
                      To administer a contest, promotion, survey or other site
                      feature.
                    </li>
                    <li>
                      <Element fontWeight="bold" as="span">
                        To send periodic emails
                      </Element>
                      . The email address you provide may be used to send you
                      information and updates pertaining to receiving occasional
                      company news, updates, related product or service
                      information, etc.
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  HOW WE PROTECT YOUR INFORMATION
                </Element>
                <Element as="p" mb={9}>
                  <Element fontWeight="bold" as="span">
                    TAJARA Hospitality
                  </Element>{" "}
                  implements physical, technical, and organizational security
                  measures aligned with industry standards in order to ensure
                  the confidentiality, integrity, and availability of any
                  personal data that is submitted to us. We educate our
                  employees on the best practices for handling your personal
                  data. Whenever we engage other organizations to provide
                  service to us, we require them to protect your personal data
                  aligned with TAJARA's security standards.
                  <br />
                  <br />
                  It is important to note that data transmission over the
                  Internet is, by nature, insecure and we cannot guarantee the
                  security of data sent over the Internet. We are not
                  responsible for any delays, crashes, and other problems caused
                  by the Internet, your Internet Service Provider, and other
                  parties involved in the transmission of data.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  HOW WE MAY SHARE YOUR INFORMATION
                </Element>
                <Element as="p" mb={9}>
                  TAJARA, subject to specific lawful exceptions, shall not
                  disclose, release or sell to third parties any personal data
                  or information given or transmitted through the Internet. This
                  does not include trusted third parties who provide assistance
                  in operating the websites, conducting business as usual or
                  servicing you, so long as those parties agree to keep this
                  information confidential. We may also release your information
                  when we believe release is appropriate to comply with the law,
                  enforce our site policies, or protect our rights, property, or
                  safety. However, non-personally identifiable visitor
                  information may be provided to other parties for marketing,
                  advertising, or other uses relevant to the brand.
                  <br />
                  <br />
                  TAJARA shall not use personal data or information given or
                  transmitted through the Internet for purposes other than what
                  has been described on this website. However, due to the nature
                  of the Internet as an unsecured medium of communication, the
                  company reserves the right to refrain from giving out an
                  absolute guarantee that the privacy or confidentiality of the
                  personal data or information given or transmitted by the user
                  is free from any unfavorable instances that may occur. TAJARA
                  shall not be held liable for damages for any loss of
                  confidentiality of any information transmitted herein.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  HOW LONG WE KEEP YOUR INFORMATION
                </Element>
                <Element as="p" mb={9}>
                  TAJARA guarantees that your personal data shall not be stored
                  longer than is necessary for as long as the purpose for which
                  it was collected, and other purposes that you may consented
                  from time to time, remain in effect until such time as it is
                  no longer required or necessary.
                  <br />
                  <br />
                  Your information shall be kept by TAJARA for the fulfillment
                  of its obligations and compliance with legal, regulatory, and
                  business requirements, or other standards set forth by a
                  governing body.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  YOUR DATA PRIVACY RIGHTS
                </Element>
                <Element mb={9}>
                  <List listStyle="disc">
                    <li>
                      Right to Information: You have the right to be informed
                      about the collection, processing, and disposal of your
                      personal data.
                    </li>
                    <li>
                      Right to Object: You have the right to object to the
                      processing of your personal data by explicitly withdrawing
                      your consent previously provided upon data collection.
                    </li>
                    <li>
                      Right to Access: Upon demand, you have the right to
                      reasonable access to the contents of your personal data.
                    </li>
                    <li>
                      Right to Rectification: You have the right to dispute the
                      inaccuracy or error in the personal data and have it
                      immediately and accordingly corrected, unless the request
                      is vexatious or otherwise unreasonable.
                    </li>
                    <li>
                      Right to Erasure or Blocking: Based on reasonable grounds,
                      you have the right to suspend, withdraw or order the
                      blocking, removal or destruction of your personal data
                      from TAJARA's system.
                    </li>
                    <li>
                      Right to Damages: Subject to the results of verification
                      of investigation, you may be indemnified for any damages
                      sustained due to unauthorized use of your personal data.
                    </li>
                    <li>
                      Right to Data Portability: You have the right to request
                      from TAJARA a copy of your personal data that is processed
                      by electronic means in a structured or commonly used
                      format.
                    </li>
                    <li>
                      Right to File a Complaint: If you feel that your personal
                      data has been misused, maliciously disclosed, or
                      improperly disposed of, or that any of your data privacy
                      rights have been violated, you have the right to file a
                      complaint with TAJARA Hospitality at
                      mychele@tajarahospitality.com
                    </li>
                  </List>
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  USE OF COOKIES
                </Element>
                <Element as="p" mb={9}>
                  Cookies consist of parcels of information, often including
                  unique identifiers that are sent by web servers to web
                  browsers. They may then be sent back to the server each time
                  the browser requests a page from the server. Cookies can be
                  used by web servers to identify and track users as they
                  navigate through different pages on a website, and to identify
                  users returning to a website. Cookies may be either
                  "persistent" cookies or "session" cookies. A persistent cookie
                  consists of a text file sent by a web server to a web browser,
                  which will be stored by the browser and will remain valid
                  until its set expiry date unless deleted by the user before
                  the expiry date. On the other hand, a session cookie will
                  expire at the end of the user session when the web browser is
                  closed.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  THIRD-PARTY LINKS
                </Element>
                <Element as="p" mb={9}>
                  The{" "}
                  <Element fontWeight="bold" as="span">
                    TAJARA Hospitality
                  </Element>{" "}
                  reserves the right to include press releases, information
                  about or pertinent to the company, or offer third party
                  products or services on third party sites. These third-party
                  sites have separate and independent privacy policies and
                  function independently. We therefore have no responsibility or
                  liability for the content and activities of these linked
                  sites. Nonetheless, we seek to protect the integrity of our
                  institution and welcome any and all feedback pertinent to
                  these websites.
                </Element>

                <Element as="p" mb={6} fontWeight="bold">
                  QUESTIONS ABOUT YOUR PRIVACY?
                </Element>
                <Element as="p" mb={4}>
                  If you have questions or concerns about our privacy practices
                  and policies, please get in touch with our Data Protection
                  Officer at:
                </Element>

                <Element as="p" fontWeight="bold" mb={0}>
                  Data Protection Officer
                </Element>
                <Element as="p" mb={9}>
                  TAJARA Leisure and Hospitality Group Inc.
                  <br />
                  Makati Junction Hostel, Don Pedro Street, Barangay Poblacion,
                  Makati City 1210 Philippines
                  <br />
                  Tel no: +632 85253801
                  <br />
                  Email: mychele@tajarahospitality.com
                </Element>
              </Tile>
            </Grid>
          </Wrapper>
        </Section>
      </BlurredSection>
    </>
  )
}

const TitleStyled = styled(Title)`
  line-height: 1;
`

export default PrivacyPolicy
